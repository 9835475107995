import * as React from 'react';
import { Loader } from '@/components/Loader';
import classNames from 'classnames';
import styles from './index.module.scss';
export var Input = function (_a) {
    var placeholder = _a.placeholder, className = _a.className, onChange = _a.onChange, onBlur = _a.onBlur, _b = _a.value, value = _b === void 0 ? '' : _b, disabled = _a.disabled, readOnly = _a.readOnly, postfix = _a.postfix, loading = _a.loading, invalid = _a.invalid, prefix = _a.prefix;
    var _c = React.useState(false), focus = _c[0], setFocus = _c[1];
    return (React.createElement("div", { className: classNames(styles.root, focus ? styles.focus : null, invalid ? styles.invalid : null, prefix ? styles.withPrefix : null, disabled ? styles.disabled : null, className) },
        prefix && (React.createElement("div", { className: styles.prefix }, prefix)),
        React.createElement("input", { type: 'text', readOnly: readOnly, className: styles.input, placeholder: placeholder, disabled: disabled, value: value, onChange: onChange, onFocus: function () { return setFocus(true); }, onBlur: function (e) {
                setFocus(false);
                onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
            } }),
        (postfix || loading) && (React.createElement("div", { className: styles.postfix },
            loading && React.createElement(Loader, { size: 16 }),
            postfix))));
};
