import * as React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
export var Button = function (_a) {
    var onClick = _a.onClick, children = _a.children, disabled = _a.disabled, className = _a.className, _b = _a.size, size = _b === void 0 ? 'm' : _b, submit = _a.submit, href = _a.href, width = _a.width, block = _a.block;
    if (href) {
        return (React.createElement("a", { href: href, onClick: onClick, target: '_blank', rel: 'noopener noreferrer', className: classNames(styles.btn, className, styles[size], block ? styles.block : undefined), style: {
                minWidth: width !== undefined ? "".concat(width, "px") : undefined,
            } }, children));
    }
    return (React.createElement("button", { type: submit ? 'submit' : 'button', onClick: onClick, disabled: disabled, className: classNames(styles.btn, className, styles[size], block ? styles.block : undefined), style: {
            minWidth: width !== undefined ? "".concat(width, "px") : undefined,
        } }, children));
};
