import { IFRAME_BASE_URL } from '@/config';
import { isTvmAddress } from '@broxus/js-core';
import { makeAutoObservable } from 'mobx';
var ConstructorStore = /** @class */ (function () {
    function ConstructorStore() {
        this.outputTokenAddress = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(ConstructorStore.prototype, "iframeURL", {
        get: function () {
            var url = new URL(IFRAME_BASE_URL);
            if (this.outputTokenAddress && isTvmAddress(this.outputTokenAddress)) {
                url.searchParams.append('token', this.outputTokenAddress);
            }
            return url.toString();
        },
        enumerable: false,
        configurable: true
    });
    return ConstructorStore;
}());
export { ConstructorStore };
