import * as React from 'react';
import { Button } from '@/components/Button';
import { Code } from '@/components/Code';
import { Input } from '@/components/Input';
import { useProvider, useStore } from '@/hooks/useStore';
import { ConstructorStore } from '@/stores/ConstructorStore';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import useClipboard from 'react-use-clipboard';
import styles from './index.module.scss';
export var Constructor = observer(function () {
    var ConstructorProvider = useProvider(ConstructorStore);
    var constructor = useStore(ConstructorStore);
    var code = "<iframe src=\"".concat(constructor.iframeURL, "\" style=\"border: 0px; height: 581px; width: 420px;\"></iframe>");
    var _a = useClipboard(code, { successDuration: 1000 }), isCopied = _a[0], setCopied = _a[1];
    return (React.createElement(ConstructorProvider, { value: constructor },
        React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.section },
                    React.createElement("h1", { className: styles.title }, "Integrate widget"),
                    React.createElement(Input, { className: styles.input, placeholder: 'Output token address', value: constructor.outputTokenAddress, onChange: action(function (e) {
                            constructor.outputTokenAddress = e.currentTarget.value;
                        }) }),
                    React.createElement(Code, { value: code }),
                    React.createElement(Button, { size: 's', onClick: setCopied, className: styles.copy }, isCopied ? 'Copied' : 'Copy')),
                React.createElement("div", { className: styles.section },
                    React.createElement("h1", { className: styles.title }, "Widget example"),
                    React.createElement("iframe", { src: constructor.iframeURL, style: {
                            border: 0,
                            height: 581,
                        } }))))));
});
